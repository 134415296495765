.targeting {
    .content {
        display: flex;
        margin: 0 1rem;

        .button {
            margin-right: 1rem;
            padding: 0.5rem 1rem;
        }

        .overlay {
            background-color: $grey;
            width: 100%;
            height: 100%;
        }
    }
}