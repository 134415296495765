.twoDSixContainer {
    padding: 1rem;

    label {
        font-weight: 500;
        font-style: normal;
    }

    input {
        width: 22rem;

        &:focus-visible {
            outline: $black solid 1px;
            outline-color: $black;
            outline-style: solid;
            outline-width: 1px;
        }
    }

    .die-results {
        display: flex;
        width: 22rem;
        flex-wrap: wrap;
        padding: 0.5em 1em;

        .die-result {
            flex: 2rem;
            flex-direction: column;
            margin: 0.5rem;

            svg, span {
                display: block;
            }

            .result-text {
                font-size: 2rem;
                font-weight: 800;
                margin-left: 1.5rem;
            }
        }
    }
}