@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;0,800;1,800&display=swap');
@import './colors.scss';
@import './typography.scss';

$font-stack: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
sans-serif;

body {
    margin: 0;
    font-family: $font-stack;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: $white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@import './basiclayout';
@import './components/button';
@import './components/ordercontainer/ordercontainer.scss';
@import './dashboard';
@import './movement';
@import './firing';
@import './targeting';
@import './attack.scss';
@import './about.scss';
@import './components/twodsixcontainer/twodsixcontainer.scss';
@import './components/dtwelvecontainer/dtwelvecontainer.scss';
