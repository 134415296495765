.dashboard {
    .content {
        margin: 1rem;

        .route {
            a {
                color: $black;
            }
        }
    }
}