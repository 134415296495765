.header {
    display: flex;
    background-color: $black;
    color: $white;
    flex-wrap: wrap;

    sub {
        font-style: italic;
        font-weight: 800;
        width: 100%;
        padding-top:1em;
        margin: 0 1rem;
    }
    h1 {
        width: 100%;
        margin-top: 0;
        margin: 0 2rem 0 1rem;
        padding-bottom: 0.5rem;
    }
}

nav {
    .back-link {
        font-weight: 800;
        font-style: italic;
        margin-left: 1em;
        color: $black;
    }
    
    border-bottom: 1px solid;
    padding: 1em 0;
}