.ordercontainer {
    padding: 1rem;

    p {
        font-weight: 600;
        font-style: normal;
        font-size: 1.5em;
    }

    .alternateoptions {
        display: flex;
        flex-direction: column;
    }
}