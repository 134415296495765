.attack {
    .content {
        display: flex;
        margin: 0 1rem;
    
        .button {
            margin-right: 1rem;
            padding: 0.5rem 1rem;
        }

        form {
            padding-top: 2rem;
        }

        label {
            display: block;
            padding: 0.5rem 0;
        }

        .overlay {
            background-color: $grey;
            width: 100%;
            height: 100%;

            .closeIcon {
                position: absolute;
                right: 1.5rem;
                cursor: pointer;
                padding-top: 0.5rem;
            }
        }
    }
}