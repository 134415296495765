.button {
    background-color: $white;
    border: 1px solid $black;
    border-radius: 5px;
    box-shadow: 2px 2px 3px $black;
    font-size: 1.5rem;
    font-weight: 800;
    font-family: $font-stack;
    padding: 0 1rem;
    width: 16em;
    margin: 1rem 0;
}